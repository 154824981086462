import {
    faArrowLeft,
    faCheckCircle,
    faChevronLeft,
    faChevronRight,
    faCogs,
    faFileInvoice,
    faPlane,
    faShoppingCart,
    faStar,
    faUsers,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AssignablePassengerOption } from "components/AncillarySelection/AncillarySelection";
import { ServiceAddObj } from "components/Fare/AncillariesModal";
import {
    FarePriceOverviewCard,
    GetTotalFarePrice,
} from "components/Fare/FarePriceOverview";
import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { ValidationErrorMessage } from "components/Shared/ErrorMessage";
import { GetNullableApiRequestDate } from "components/Shared/Helpers/DateConverters";
import { PriceDisplay } from "components/Shared/PriceDisplay";
import ValidIndicatorIcon from "components/Shared/ValidIndicatorIcon";
import { useAvailableFaresClient } from "hooks/useHttpClient";
import { useSessionHandler } from "hooks/useSessionHandler";
import { SetBookingPayload } from "Pages/AvailableFaresPage";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import {
    Session_Reducer_HideCustomerWaitScreen,
    Session_Reducer_HideGeneralWaitScreen,
    Session_Reducer_PushTransaction,
    Session_Reducer_SetWatiScreenInfo,
    Session_Reducer_ShowCustomerWaitScreen,
    Session_Reducer_ShowGeneralWaitScreen,
} from "rootExports/SessionReducer";
import {
    Adress,
    AgentBookFareConfiguration,
    AncillarySegmentBookRequest,
    AncillarySegmentIdentifier,
    BookingAddressBehavior,
    FareBookingInputPassengerModel,
    FareBookingLegIdentifier,
    FareBookingOnHoldEnum,
    FareBookingPassengerView,
    FareBookingSubmitModel,
    FareBookingTicketGroupOptions,
    FareRebookingSubmitModel,
    SmartBookingApiResponse,
    SmartBookResponseDataModel,
} from "WebApiClient";
import FareBookingConfirmation from "../../BookingConfirmation/BookingConfirmation";
import { defaultAdress } from "../types/FareBookingTypes";
import FareBookingAddress from "./FareBooking.Address";
import FareBookingAncillaries from "./FareBooking.Ancillaries";
import FareBookingOptions from "./FareBooking.BookingOptions";
import FareBookingOther from "./FareBooking.Other";
import FareBookingPassengers from "./FareBooking.Passengers";
import FareBookingSelectedFlight from "./FareBooking.SelectedFlight";
import {
    BookingValid,
    BookingValidResult,
    DefaultBookingValidResult,
} from "./FareBooking.Validate";
import { State } from "rootExports/rootReducer";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useUserInformation } from "hooks/various";
import { booking_Redirect_Reducer } from "rootExports/BookingRedirectReducer";

export const FareBookingShell: React.FC<{
    Intit: SetBookingPayload | undefined;
    Discard: () => void;
    OnNewQuery: () => void;
    GoToFares: () => void;
    multiChannel?: any;
    OnModify: () => void;
}> = (props) => {
    const multiChannal = props.multiChannel;
    const availFareClient = useAvailableFaresClient();
    const [config, setConfig] = useState<
        AgentBookFareConfiguration | undefined
    >(undefined);
    const [errorOccured, setErrorOccured] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        FetchConfig();
    }, []);

    function FetchConfig() {
        setLoading(true);
        availFareClient.getBookingConfiguration()
            .then((response) => {
                setConfig(response);
                setErrorOccured(false);
            })
            .catch(() => {
                setErrorOccured(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <div>
            {loading && (
                <div className="card-body">Loading Configuration...</div>
            )}
            {errorOccured && (
                <div className="card-body">
                    An error has occured while fetching configuration.
                </div>
            )}
            {config && props.Intit && (
                <FareBookingNew
                    GoToFares={props.GoToFares}
                    configuration={config}
                    init={props.Intit}
                    multiChannel={props.multiChannel}
                    OnNewQuery={props.OnNewQuery}
                    OnModify={props.OnModify}
                />
            )}
        </div>
    );
};

interface PassengerData {
    firstName: string;
    lastName: string;
    birthDate: string;
    gender: number;
    passengerID: string;
    passengerNumber: number;
    type: string;  // Original 'type' key from the server
    ticketNumbers: string[];
}

const FareBookingNew: React.FC<{
    init: SetBookingPayload;
    configuration: AgentBookFareConfiguration;
    OnNewQuery: () => void;
    GoToFares: () => void;
    multiChannel: any;
    OnModify: () => void;
}> = (props) => {
    var otherSettings: string = useSelector((state: State) => {
        if (
            state.Session.FrontendSettings.otherSettingsAsKeyValueText ==
            null ||
            state.Session.FrontendSettings.otherSettingsAsKeyValueText.trim() ===
            ""
        ) {
            return state.Session.FrontendSettings
                .otherSettingsAsKeyValueText ===
                "menuPosition=L\nshowFareHeader=Off"
                ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
                : `menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}
        showFareHeader=Off
        showSmartCalender=None/n[]/nfalse/nfalse{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}\n0\nshowHotelTab=None\nshowCabinContainer=All`;
        }
        return state.Session.FrontendSettings.otherSettingsAsKeyValueText;
    });
    let settingArr = otherSettings.split("\n");
    let DateType = {
        PassengerGender: "Unselected",
        PassengerDOB: "No",
        DOB: 0,
    };
    if (typeof settingArr[5] === "string" && settingArr[5].length) {
        try {
            DateType = JSON.parse(settingArr[5]);
        } catch (e) {
            console.error("Error parsing JSON:", e);
            DateType = {
                PassengerGender: "Unselected",
                PassengerDOB: "No",
                DOB: 0,
            };
        }
    } else if (typeof settingArr[5] === "object" && settingArr[5] !== null) {
        DateType = settingArr[5];
    } else {
        DateType = {
            PassengerGender: "Unselected",
            PassengerDOB: "No",
            DOB: 0,
        };
    }
    const multiChannel = props.multiChannel;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userInfo = useUserInformation();
    const availFareClient = useAvailableFaresClient();
    const fare = props.init.Fare;
    const [selectedConnections, setSelectedConnections] = useState<number[]>(
        props.init.SelectedConnections
    );
    const [errorOccured, setErrorOccured] = useState(false);
    const [bookingResponse, setBookingResponse] = useState<
        SmartBookingApiResponse | undefined
    >(undefined);
    const bookingResponseResult = bookingResponse?.result;
    const validationResult = bookingResponseResult?.validationResult;
    const bookingResult: SmartBookResponseDataModel | undefined =
        bookingResponseResult?.result;
    const [ticketGroupOptions, setticketGroupOptions] = useState<FareBookingTicketGroupOptions[]
    >(
        fare.ticketGroupOptions
            ? fare.ticketGroupOptions.map((val) => {
                const op: FareBookingTicketGroupOptions = {
                    ticketGroupID: val.ticketGroupID,
                    bookingOnHoldSelection:
                        FareBookingOnHoldEnum.NotApplicable,
                };
                return op;
            })
            : []
    );
    const [address, setAddress] = useState<Adress | undefined>(
        props.configuration.address!
    );

    // const [address, setAddress] = useState<Adress>(
    //     props.configuration.addressBehavior ===
    //         BookingAddressBehavior.EmptyUserInput
    //         ? defaultAdress
    //         : props.configuration.address!
    // );
    const [invoice, setInvoice] = useState<Adress>(
        // props.configuration.invoice == undefined ? defaultAdress : props.configuration.invoice!
        props.configuration.addressBehavior ===
            BookingAddressBehavior.EmptyUserInput
            ? defaultAdress
            : props.configuration.address!
    );
    const [webfarePassword, setWebfarePassword] = useState<string>(
        props.configuration.webfarePasswordBehavior ===
            BookingAddressBehavior.EmptyUserInput
            ? ""
            : props.configuration.webfarePassword!
    );
    const [emergencyPhone, setEmergencyPhone] = useState<string>(
        props.configuration.emergencyPhoneBehavior ===
            BookingAddressBehavior.EmptyUserInput
            ? ""
            : props.configuration.emergencyphone!
    );

    const [bookingConfirmationEmail, setBookingConfirmationEmail] = useState<string>(props.configuration.bookingConfirmationEmail!)
    const [passengers, setPassengers] = useState<FareBookingPassengerView[]>(
        fare.bookingPassengers ? fare.bookingPassengers : []
    );
    const [reviewedChecked, setReviewedChecked] = useState(false);
    const sessionHandler = useSessionHandler();
    const [addedServices, setAddedServices] = useState<ServiceAddObj[]>(
        props.init.AddedServices
    );
    //payment
    const [paymentBOFDetails, setPaymentBOFDetails] = useState<[]>([]);

    const [paymentDataBackend, setPaymentDataBackend] = useState<[]>([]);
    useEffect(() => {
        setSelectedConnections(props.init.SelectedConnections);
    }, [props.init.SelectedConnections]);
    function GetAssignablePassengers(): AssignablePassengerOption[] {
        let result: AssignablePassengerOption[] = [];

        passengers.forEach((element, index) => {
            const pData = element.bookingData;
            const useCustomLabel =
                (pData.firstName !== undefined && pData.firstName.length > 0) ||
                (pData.lastName !== undefined && pData.lastName.length > 0);
            const label = useCustomLabel
                ? `${pData.title ? pData.title : ""} ${pData.firstName ? pData.firstName : ""
                } ${pData.lastName ? pData.lastName : ""} (${pData.passengerType
                })`
                : `${pData.passengerType} ${index + 1}`;
            const option: AssignablePassengerOption = {
                Index: index,
                Label: label,
                PassengerType: pData.passengerType,
            };
            result.push(option);
        });
        return result;
    }


    // console.log("paymentdata-------------", paymentBOFDetails);

    const bookingRedirect: any = useSelector((state: any) => state.bookingRedirectSession.value);
    const AssignablePassengers = GetAssignablePassengers();
    const [activeTab, setActiveTab] = useState(1);
    const selectConnectionTabIndex = bookingRedirect?.checkActiveBtnClicked !== "Rebook" ? 1 : 7;
    const passengersTabIndex = 2;
    const requiredInfoTabIndex = 3;
    const servicesTabIndex = 4;
    const bookingOptionsTabIndex = 5;
    const addressTabIndex = 6;
    const bookTabIndex = bookingRedirect?.checkActiveBtnClicked !== "Rebook" ? 7 : 1;
    const confirmationTabIndex = 8;
    const maxTab = bookingResult ? confirmationTabIndex : confirmationTabIndex - 1;

    function IncrementTab() {
        if (activeTab === passengersTabIndex && !ShowRequiredInfoTab) {
            setActiveTab(servicesTabIndex);
        } else if (activeTab === servicesTabIndex && !ShowBookingOptionsTab) {
            setActiveTab(addressTabIndex);
        } else {
            if (activeTab < maxTab) {
                setActiveTab(activeTab + 1);
            }
        }
    }
    function DecrementTab() {
        if (activeTab === servicesTabIndex && !ShowRequiredInfoTab) {
            setActiveTab(passengersTabIndex);
        } else if (activeTab === addressTabIndex && !ShowBookingOptionsTab) {
            setActiveTab(servicesTabIndex);
        } else {
            if (activeTab > 1) {
                setActiveTab(activeTab - 1);
            } else {
            }
        }
    }
    const toggle = (tab: number) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const nextTabDisabled = activeTab === maxTab;
    const prevTabDisabled = activeTab === 1;
    function OnSelectConnection(legIndex: number, connectionIndex: number) {
        let stateCopy = [...selectedConnections];
        stateCopy[legIndex] = connectionIndex;
        if (addedServices.length > 0) {
            setAddedServices([]);
            console.log(
                "Book page: Ancillaries were resettet because of connection change"
            );
        }
        setSelectedConnections(stateCopy);
        props.init.OnSelectConnection(legIndex, connectionIndex);
    }
    function OnBookingOnHoldSelectionChange(index: number, value: number) {
        let stateCopy = [...ticketGroupOptions];
        stateCopy[index].bookingOnHoldSelection = value;
        setticketGroupOptions(stateCopy);
    }
    const ValidationResult: BookingValidResult = fare
        ? BookingValid(
            paymentDataBackend,
            paymentBOFDetails,
            passengers,
            fare,
            address,
            invoice,
            props.configuration.addressBehavior,
            webfarePassword,
            emergencyPhone,
            props.configuration.webfarePasswordBehavior,
            props.configuration.emergencyPhoneBehavior
        ) : DefaultBookingValidResult;
    function OnUpdateWebfarePassword(val: string) {
        setWebfarePassword(val);
    }
    function OnUpdateEmergencyPhone(val: string) {
        setEmergencyPhone(val);
    }
    function OnUpdateAddress(val: Adress | any) {
        setAddress(val);
    }
    function OnUpdateInvoice(val: Adress) {
        setInvoice(val);
    }
    function OnupdatePaymentDetails(paymentData: []) {
        console.log(paymentData);
    }
    function OnPassengerUpdate(
        index: number,
        passenger: FareBookingPassengerView
    ) {
        let stateCopy = [...passengers];
        stateCopy[index] = passenger;
        setPassengers(stateCopy);
    }
    function createLegIdentifiers(): FareBookingLegIdentifier[] {
        return selectedConnections.map((e, index) => ({
            legIndex: index,
            connectionIndex: e,
        }));
    }
    function createSubmitModel(): FareBookingSubmitModel {
        return {
            passengers: GetSubmitPassengers(),
            fareResultID: fare.identifier!.fareResultID,
            fareIndex: fare.fareIndex,
            legIdentifiers: createLegIdentifiers(),
            ticketGroupOptions: ticketGroupOptions,
            adress: address ?? invoice,
            otherInput: createOtherInput(),
            isLiveBooking: true,
            invoiceAdress: invoice,
            MultiChannelBranchGroup: multiChannel.branchgroup,
            MultiChannelBranch: multiChannel.branch,
            UsedRuleName: multiChannel.branch,
            DobHandling: DateType?.DOB,
        };
    }
    function createRebookingSubmitModel(): FareRebookingSubmitModel {
        return {
            SegmentSelectionToBeReplaced: [bookingRedirect.bookingSegmentsData[0].segmentNumber],
            bookingIdentifier: createBookingIdentifier(),
            Replacement: {
                passengers: mapPassengerData(),
                fareResultID: fare.identifier!.fareResultID,
                fareIndex: fare.fareIndex,
                legIdentifiers: createLegIdentifiers(),
                ticketGroupOptions: ticketGroupOptions,
                adress: address ?? invoice,
                otherInput: createOtherInput(), // Uncomment and adjust if needed
                isLiveBooking: true,
                invoiceAdress: invoice,
                MultiChannelBranchGroup: multiChannel.branchgroup,
                MultiChannelBranch: multiChannel.branch,
                UsedRuleName: multiChannel.branch,
                DobHandling: DateType?.DOB,
            },
        };
    }
    function createBookingIdentifier() {
        const { cartID, bookingItemID } = bookingRedirect?.bookingSegmentsData[bookingRedirect?.selectedSegment];
        return {
            cartId: cartID,
            bookingId: bookingItemID,
            emailIds: null,
            description: null,
            templateName: null,
            passengerSelection: null,
            segmentSelection: null,
            emdSelection: null,
        };
    }
    function mapPassengerData() {

        return bookingRedirect?.bookingSegmentPassengerData.map(
            ({ type, ...rest }: PassengerData) => ({
                ...rest,
                passengerType: type,
            })
        );
    }

    function createOtherInput() {
        const otherInput: any = {
            webfarePassword: webfarePassword,
            emergencyPhone: emergencyPhone,
        };

        if (bookingRedirect?.checkActiveBtnClicked !== "Rebook") {
            otherInput.paymentBOFDetails = paymentBOFDetails.flatMap((obj) =>

                Object.entries(obj).filter(([key]) => key !== "null" && key !== "passengerSelection").flatMap(([key, value]) => {
                    console.log(key, value);
                    if (Array.isArray(value)) {
                        return value.filter((val) => val !== "" && val !== null)
                            .map((val) => ({
                                key,
                                value: val,
                                passengerSelection: (obj as any).passengerSelection == null ? null : [(obj as any).passengerSelection],
                            }));

                    } else if (value !== "") {
                        return [
                            {
                                key,
                                value,
                                passengerSelection: (obj as any).passengerSelection == null ? null : [(obj as any).passengerSelection],
                            },
                        ];
                    }
                    return [];
                })
            ).filter(Boolean);
        }

        return otherInput;
    }


    async function handleBookingSubmission(model: any) {
        try {
            var response: any;
            if (bookingRedirect?.checkActiveBtnClicked === "Rebook") {
                response = await availFareClient.submitReBooking(model);
                if (response?.responseMetaData.errorOccured == false) {
                    toast.success("Rebook successfully");
                    dispatch(booking_Redirect_Reducer({ bookingRedirect: false, checkActiveBtnClicked: "", bookingSegmentsData: [], bookingSegmentsDataCustom: {}, bookingSegmentPassengerData: [], recordLocator: '' }))
                    setTimeout(() => {
                        navigate(
                            `/auth/${userInfo.WebID}/${userInfo.Deeplink}/bookingmanager`
                        );
                    }, 3000);
                    setErrorOccured(false);
                }
            } else {
                response = await availFareClient.submitBooking(model);
            }
            dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
            setBookingResponse(response);
            handleBookingResponse(response);



            if (response?.responseMetaData.errorOccured == true) {
                setErrorOccured(true);
            }
            // console.log('response',response)
        } catch (error) {
            setErrorOccured(true);
            // console.log('error occured',error)
        } finally {
            dispatch(Session_Reducer_HideCustomerWaitScreen());
            dispatch(Session_Reducer_HideGeneralWaitScreen());
            sessionHandler.RefreshSession();
        }
    }
    function handleBookingResponse(response: any) {
        const result = response.result;
        if (!result || (result.validationResult.isValid && result.result === undefined)) {
            if (response?.responseMetaData.errorOccured == true) {
                setErrorOccured(true);
            }
            setActiveTab(bookTabIndex);
        } else if (!result.validationResult.isValid) {
            setErrorOccured(false);
            setActiveTab(bookTabIndex);
        } else {
            setErrorOccured(false);
            setActiveTab(confirmationTabIndex);
        }
    }
    function OnSubmit() {
        dispatch(Session_Reducer_ShowCustomerWaitScreen());
        dispatch(Session_Reducer_ShowGeneralWaitScreen());
        dispatch(
            Session_Reducer_SetWatiScreenInfo({
                Label: "Booking selected flight...",
                Percentage: 50,
            })
        );
        window.scrollTo(0, 0);
        const submitModel = bookingRedirect?.checkActiveBtnClicked === "Rebook"
            ? createRebookingSubmitModel()
            : createSubmitModel();
        handleBookingSubmission(submitModel);
    }
    const ShowEmergencyPhone =
        props.configuration.emergencyPhoneBehavior !==
        BookingAddressBehavior.UseBranchAdress;
    const ShowWebfarePassword = ValidationResult.Other.WebfarePasswordRequired;
    const ShowRequiredInfoTab = ShowEmergencyPhone || ShowWebfarePassword;
    function DetectBookingOptionsPossible(): boolean {
        let result = false;
        fare.ticketGroupOptions.forEach((t) => {
            if (t.bookOnHoldOptions!.bookOnHoldPossible) {
                result = true;
            }
        });
        return result;
    }
    const ShowBookingOptionsTab = DetectBookingOptionsPossible();
    const selectConnectionIcon = <FontAwesomeIcon icon={faPlane} />;
    const renderSelectedFlight = (
        <FareBookingSelectedFlight
            Fare={props.init.Fare}
            SelectedConnections={selectedConnections}
            OnSelectConnection={OnSelectConnection}
            Icon={selectConnectionIcon}
            Passengers={AssignablePassengers}
            AddedServices={addedServices}
        />
    );
    const passengersIcon = (
        <ValidIndicatorIcon
            IsValid={
                ValidationResult.PassengersValid &&
                ValidationResult.Other.paymentBOFDetails.all
            }
            Icon={faUsers}
            NoValueSet={false}
        />
    );
    const renderPassengers = (
        <FareBookingPassengers
            Passengers={passengers}
            ValidationResult={ValidationResult}
            OnPassengerUpdate={OnPassengerUpdate}
            OnPaymentDetails={OnupdatePaymentDetails}
            Apisrequirements={fare.apisRequirenments!}
            Icon={passengersIcon}
            setPaymentBOFDetails={setPaymentBOFDetails}
            paymentBOFDetails={paymentBOFDetails}
            setPaymentDataBackend={setPaymentDataBackend}
            paymentValid={ValidationResult.Other.paymentBOFDetails}
        />
    );
    const otherIcon = (
        <ValidIndicatorIcon
            IsValid={ValidationResult.Other.Valid}
            Icon={faFileInvoice}
            NoValueSet={false}
        />
    );
    const renderServices = (
        <FareBookingAncillaries
            Fare={props.init.Fare}
            SelectedConnections={selectedConnections}
            SetSelectedConnection={OnSelectConnection}
            AddedServices={addedServices}
            SetAddedServices={(service: ServiceAddObj[]) => {
                setAddedServices(service);
            }}
            Passengers={AssignablePassengers}
        />
    );
    const servicesIcon = (
        <ValidIndicatorIcon IsValid={true} Icon={faStar} NoValueSet={false} />
    );
    const renderOther1 = (
        <FareBookingOther
            WebfarePassword={webfarePassword}
            WebfarePasswordBehavior={
                props.configuration.webfarePasswordBehavior
            }
            EmergencyPhone={emergencyPhone}
            EmergencyPhoneBehavior={props.configuration.emergencyPhoneBehavior}
            OnUpdateWebfarePassword={OnUpdateWebfarePassword}
            OnUpdateEmergencyPhone={OnUpdateEmergencyPhone}
            IsValid={ValidationResult.Other}
            Icon={otherIcon}
            IsOpen={false}
        />
    );
    const renderOther2 = (
        <FareBookingOther
            WebfarePassword={webfarePassword}
            WebfarePasswordBehavior={
                props.configuration.webfarePasswordBehavior
            }
            EmergencyPhone={emergencyPhone}
            EmergencyPhoneBehavior={props.configuration.emergencyPhoneBehavior}
            OnUpdateWebfarePassword={OnUpdateWebfarePassword}
            OnUpdateEmergencyPhone={OnUpdateEmergencyPhone}
            IsValid={ValidationResult.Other}
            Icon={otherIcon}
            IsOpen={true}
        />
    );
    const bookingOptionsIcon = <FontAwesomeIcon icon={faCogs} />;
    const renderBookingOptions = (
        <FareBookingOptions
            AvailableTicketGroupOptions={fare.ticketGroupOptions!}
            SelectedticketGroupOptions={ticketGroupOptions}
            OnBookingOnHoldSelectionChange={OnBookingOnHoldSelectionChange}
            Icon={bookingOptionsIcon}
        />
    );
    const adressIcon = (
        <ValidIndicatorIcon
            IsValid={
                ValidationResult.Address.Valid && ValidationResult.Invoice.Valid
            }
            Icon={faFileInvoice}
            NoValueSet={false}
        />
    );
    const renderAdress1 = (
        <FareBookingAddress
            EmergencyPhone={emergencyPhone}
            BookingConfirmationEmail={bookingConfirmationEmail}
            Passengers={passengers}
            Address={address}
            OnUpdateAddress={OnUpdateAddress}
            AdressValid={ValidationResult.Address}
            AddressBehavior={props.configuration.addressBehavior}
            Icon={adressIcon}
            Invoice={invoice}
            OnUpdateInvoice={OnUpdateInvoice}
            InvoiceValid={ValidationResult.Invoice}
            IsOpen={true}
        />
    );
    const renderAdress2 = (
        <FareBookingAddress
            EmergencyPhone={emergencyPhone}
            BookingConfirmationEmail={bookingConfirmationEmail}
            Passengers={passengers}
            Address={address}
            OnUpdateAddress={OnUpdateAddress}
            AdressValid={ValidationResult.Address}
            AddressBehavior={props.configuration.addressBehavior}
            Icon={adressIcon}
            Invoice={invoice}
            OnUpdateInvoice={OnUpdateInvoice}
            InvoiceValid={ValidationResult.Invoice}
            IsOpen={false}
        />
    );

    const checkAndSubmitIcon = (
        <ValidIndicatorIcon
            IsValid={(bookingRedirect?.checkActiveBtnClicked == "Rebook" || bookingRedirect?.checkActiveBtnClicked == "Add") ? true :
                ValidationResult.Valid &&
                ValidationResult.Other.paymentBOFDetails.all
            }
            Icon={faShoppingCart}

            NoValueSet={false}
        />
    );

    function GetSubmitPassengers(): FareBookingInputPassengerModel[] {
        let result: FareBookingInputPassengerModel[] = [];
        passengers.forEach((passenger, index) => {
            const services = GetAncillaryForPassenger(passenger, index);
            result.push({
                ...passenger.bookingData,
                ancillaries: services,
                optionalDateOfBirth: GetNullableApiRequestDate(
                    passenger.bookingData.dateOfBirth
                ),
                dateOfBirth: undefined,
            });
        });
        return result;
    }

    function GetAncillaryForPassenger(
        passenger: FareBookingPassengerView,
        index: number
    ): AncillarySegmentBookRequest[] {
        let result: AncillarySegmentBookRequest[] = [];
        const legPassengerServices: ServiceAddObj[] = addedServices.filter(
            (e) => e.PassengerIndex === index
        );
        legPassengerServices.forEach((addedService) => {
            let segments: AncillarySegmentIdentifier[] = [];
            addedService.Segments.forEach((segment) => {
                const requestSegment: AncillarySegmentIdentifier = {
                    arrival: segment.arrival,
                    departure: segment.departure,
                };
                segments.push(requestSegment);
            });
            const serviceAdd: AncillarySegmentBookRequest = {
                bookingCode: addedService.Service.bookingCode,
                bookingType: addedService.Service.bookingType,
                carrierCode: addedService.Service.serviceCarrier,
                date: undefined,
                location: addedService.Service.serviceLocation,
                segments: segments,
                text: addedService.Service.text,
                id: addedService.Service.id,
                extensions: addedService.Service.extensions,
                serviceType: addedService.Service.serviceType,
            };
            result.push(serviceAdd);
        });
        return result;
    }
    const totalFarePrice = GetTotalFarePrice(
        props.init.Fare.totalPrice,
        addedServices
    );
    function OnModify() {
        props.OnModify();
    }

    return (
        <Fragment>
            <div className="card">
                <div className="card-body">
                    {bookingRedirect?.bookingRedirect ? null :
                        <Nav tabs pills>
                            {bookingRedirect?.checkActiveBtnClicked ==
                                "Rebook" || bookingRedirect?.checkActiveBtnClicked == "Add" ? null : (
                                <NavItem>
                                    <NavLink
                                        className={`${activeTab === selectConnectionTabIndex
                                            ? "active"
                                            : ""
                                            } clickme`}
                                        onClick={() => {
                                            toggle(selectConnectionTabIndex);
                                        }}
                                    >
                                        {selectConnectionIcon} Select connection
                                    </NavLink>
                                </NavItem>
                            )}
                            {bookingRedirect?.checkActiveBtnClicked ==
                                "Rebook" || bookingRedirect?.checkActiveBtnClicked == "Add" ? null : (
                                <NavItem>
                                    <NavLink
                                        className={`${activeTab === passengersTabIndex
                                            ? "active"
                                            : ""
                                            } clickme`}
                                        onClick={() => {
                                            toggle(passengersTabIndex);
                                        }}
                                    >
                                        {passengersIcon} Passengers
                                    </NavLink>
                                </NavItem>
                            )}

                            {bookingRedirect?.checkActiveBtnClicked == "Rebook" || bookingRedirect?.checkActiveBtnClicked == "Add" ? null : (
                                <>
                                    {ShowRequiredInfoTab && (
                                        <NavItem>
                                            <NavLink
                                                className={`${activeTab ===
                                                    requiredInfoTabIndex
                                                    ? "active"
                                                    : ""
                                                    } clickme`}
                                                onClick={() => {
                                                    toggle(requiredInfoTabIndex);
                                                }}
                                            >
                                                {otherIcon} Required Information
                                            </NavLink>
                                        </NavItem>
                                    )}
                                </>
                            )}

                            {bookingRedirect?.checkActiveBtnClicked ==
                                "Rebook" || bookingRedirect?.checkActiveBtnClicked == "Add" ? null :
                                <NavItem>
                                    <NavLink
                                        className={`${activeTab === servicesTabIndex
                                            ? "active"
                                            : ""
                                            } clickme`}
                                        onClick={() => {
                                            toggle(servicesTabIndex);
                                        }}
                                    >
                                        {servicesIcon} Ancillaries
                                    </NavLink>
                                </NavItem>
                            }

                            {ShowBookingOptionsTab && (
                                <NavItem>
                                    <NavLink
                                        className={`${activeTab === bookingOptionsTabIndex
                                            ? "active"
                                            : ""
                                            } clickme`}
                                        onClick={() => {
                                            toggle(bookingOptionsTabIndex);
                                        }}
                                    >
                                        {bookingOptionsIcon} Booking options
                                    </NavLink>
                                </NavItem>
                            )}
                            {bookingRedirect?.checkActiveBtnClicked ==
                                "Rebook" || bookingRedirect?.checkActiveBtnClicked == "Add" ? null : (
                                <NavItem>
                                    <NavLink
                                        className={`${activeTab === addressTabIndex
                                            ? "active"
                                            : ""
                                            } clickme`}
                                        onClick={() => {
                                            toggle(addressTabIndex);
                                        }}
                                    >
                                        {adressIcon} Delivery {"&"} Invoice
                                    </NavLink>
                                </NavItem>
                            )}

                            <NavItem>
                                <NavLink
                                    className={`${activeTab === bookTabIndex ? "active" : ""
                                        } clickme`}
                                    onClick={() => {
                                        toggle(bookTabIndex);
                                    }}
                                >
                                    {checkAndSubmitIcon} Check {"&"} Book
                                </NavLink>
                            </NavItem>

                            {bookingRedirect?.checkActiveBtnClicked ==
                                "Rebook" ? null : (
                                <NavItem>
                                    <NavLink
                                        disabled={!bookingResult}
                                        className={`${activeTab === 8 ? "active" : ""
                                            } clickme`}
                                        onClick={() => {
                                            toggle(8);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faCheckCircle} />{" "}
                                        Confirmation
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    }
                    <TabContent activeTab={activeTab} className="mt-2">

                        <TabPane tabId={selectConnectionTabIndex}>
                            {renderSelectedFlight}
                        </TabPane>

                        <TabPane tabId={passengersTabIndex}>
                            {renderPassengers}
                        </TabPane>
                        <TabPane tabId={requiredInfoTabIndex}>
                            {renderOther1}
                        </TabPane>
                        <TabPane tabId={servicesTabIndex}>
                            {renderServices}
                        </TabPane>
                        <TabPane tabId={bookingOptionsTabIndex}>
                            {renderBookingOptions}
                        </TabPane>
                        <TabPane tabId={addressTabIndex}>
                            {renderAdress1}
                        </TabPane>
                        <TabPane tabId={bookTabIndex}>
                            <div className="row mb-2">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header card-header-primary">
                                            CONFIRM AND BOOK
                                        </div>
                                        <div className="card-body card-body-primary">
                                            <div className="row">
                                                {(!ValidationResult.Valid ||
                                                    !ValidationResult.Other.paymentBOFDetails.all) &&
                                                    bookingRedirect?.checkActiveBtnClicked !=
                                                    "Rebook" || bookingRedirect?.checkActiveBtnClicked == "Add" && (
                                                        <div className="col-12 mb-2">
                                                            <Alert color="danger">
                                                                There are some
                                                                fields that
                                                                require
                                                                attention.
                                                                Please review
                                                                your input.
                                                            </Alert>
                                                        </div>
                                                    )}

                                                {validationResult &&
                                                    (bookingRedirect?.checkActiveBtnClicked !=
                                                        "Rebook" || bookingRedirect?.checkActiveBtnClicked == "Add") && (
                                                        <div className="col-12 mb-2">
                                                            <ValidationErrorMessage
                                                                error={
                                                                    !validationResult.isValid
                                                                }
                                                                messages={
                                                                    validationResult.errorSummary
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                {errorOccured && (
                                                    <ErrorDisplay
                                                        data={
                                                            bookingResponse?.responseMetaData
                                                        }
                                                    />
                                                )}
                                                <div
                                                    className="col-12"
                                                    onClick={() => {
                                                        setReviewedChecked(
                                                            !reviewedChecked
                                                        );
                                                    }}
                                                >
                                                    <div className="form-check">
                                                        <input
                                                            checked={
                                                                reviewedChecked
                                                            }
                                                            onChange={() => { }}
                                                            type="checkbox"
                                                            className="form-check-input"
                                                        />
                                                        <label className="form-check-label">
                                                            I have reviewed my
                                                            entered information.
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 mb-2">
                                                    <div className="d-grid">
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={OnSubmit}
                                                            disabled={
                                                                (bookingRedirect?.checkActiveBtnClicked ==
                                                                    "Rebook" || bookingRedirect?.checkActiveBtnClicked == "Add") &&
                                                                    reviewedChecked
                                                                    ? false
                                                                    : !ValidationResult.Valid ||
                                                                    !reviewedChecked ||
                                                                    !ValidationResult
                                                                        .Other
                                                                        .paymentBOFDetails
                                                            }
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faShoppingCart
                                                                }
                                                            />{" "}
                                                            Book now for{" "}
                                                            <PriceDisplay
                                                                currency={
                                                                    props.init
                                                                        .Fare
                                                                        .currency!
                                                                }
                                                                price={
                                                                    totalFarePrice
                                                                }
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-12 ">
                                                    <FarePriceOverviewCard
                                                        AssignablePassengers={
                                                            AssignablePassengers
                                                        }
                                                        Currency={
                                                            props.init.Fare
                                                                .currency!
                                                        }
                                                        FarePrice={
                                                            props.init.Fare
                                                                .totalPrice
                                                        }
                                                        Services={addedServices}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {renderSelectedFlight}
                            {
                                bookingRedirect?.checkActiveBtnClicked == "Rebook" || bookingRedirect?.checkActiveBtnClicked == "Add" ? null :
                                    <>
                                        {renderPassengers}
                                        {renderOther2}
                                        {renderBookingOptions}
                                        {renderAdress2}
                                    </>
                            }
                        </TabPane>
                        <TabPane tabId={8}>
                            {bookingResult && (
                                <FareBookingConfirmation
                                    Result={bookingResult}
                                    OnNewQuery={props.OnNewQuery}
                                />
                            )}
                        </TabPane>
                    </TabContent>
                </div>
            </div>

            <div style={{ position: "absolute", top: "50vh", right: "15px" }}>
                <div className="btn-group btn-group-vertical">
                    <button
                        className="btn btn-primary btn-lg mb-2"
                        onClick={IncrementTab}
                        disabled={nextTabDisabled || bookingRedirect?.checkActiveBtnClicked == "Rebook"}
                    >
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                    <button
                        className="btn btn-primary btn-lg mb-2"
                        onClick={
                            prevTabDisabled ? props.GoToFares : DecrementTab
                        }
                        disabled={true ? false : prevTabDisabled}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                </div>
            </div>
            <div style={{ position: "absolute", bottom: "1vh", right: "15px" }}>
                <div className="btn-group btn-group-vertical">
                    <button
                        className="btn btn-lg btn-cancel"
                        onClick={OnModify}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} /> Modify
                    </button>
                </div>
            </div>
        </Fragment>
    );
};

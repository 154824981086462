import {
  faAngleDoubleDown,
  faAngleDoubleUp,
} from "@fortawesome/pro-light-svg-icons";
import { faBaby, faChild, faMale } from "@fortawesome/pro-regular-svg-icons";
import {
  faEye,
  faEyeSlash,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ValidIndicatorIcon from "components/Shared/ValidIndicatorIcon";
import React, { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import {
  FareAPISRequirements,
  FarebookingFormOfIdentifikationModel,
  FareBookingInputPassengerModel,
  FareBookingPassengerApis,
  FareBookingPassengerView,
} from "WebApiClient";
import BookingPassengerApisTab from "./FareBooking.Passengers.Passenger.Apis";
import BookingPassengerFoidTab from "./FareBooking.Passengers.Passenger.Foid";
import BookingPassengerGeneralTab from "./FareBooking.Passengers.Passenger.General";
import BookingPassengerOtherTab from "./FareBooking.Passengers.Passenger.Other";
import { BookPassengerValidationResult } from "./FareBooking.Validate.Passenger";
import PaymentDataTab from "./FareBooking.Passengers.Passenger.Payment";
import PassengerJson from "./passenger.json";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
export interface IBookingPassengerProps {
  Apisrequirements: FareAPISRequirements;
  Passenger: FareBookingPassengerView;
  OnUpdatePassenger: (index: number, data: FareBookingPassengerView) => void;
  // OnUseAdressForAll: (index: number, useAdressForAll: boolean) => void;
  PassengersAmount: number;
  Index: number;
  // CanInputAdress: boolean;
  Valid: BookPassengerValidationResult;
  IsOpen: boolean;
  Toggle: (index: number) => void;
  HasNext: boolean;
  ExpandNext: (index: number) => void;
  setPaymentBOFDetails: any;
  paymentBOFDetails: any;
  setPaymentDataBackend: any;
  paymentValid: any;
}

export interface passengerConfig {
  PassengerDOB: string;
  PassengerGender: string;
}



export const BookingPassenger: React.FC<IBookingPassengerProps> = (props) => {
  var otherSettings: string = useSelector((state: State) =>
    state.Session.FrontendSettings.otherSettingsAsKeyValueText == null
      ? state.Session.FrontendSettings.otherSettingsAsKeyValueText ==
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
        : 'menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}showFareHeader=OffshowSmartCalender=None/n[]/nfalse/nfalse/n{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}0{"PassengerGender":"Unselected","PassengerDOB":"No"}showHotelTab=All{"Container":"#BRANCHCHANGE#"}showCabinContainer=None'
      : state.Session.FrontendSettings.otherSettingsAsKeyValueText
  );

  let settingArr = otherSettings.split("\n");
  var passengerConfig: passengerConfig = settingArr[5] == undefined ? { PassengerDOB: "No", PassengerGender: "Unselected" } : JSON.parse(settingArr[5]);

  const passenger: any = props.Passenger;
  const passengerTypeChanged = passenger.resultPassengerAbbrevation !== passenger.originalPassengerAbbrevation;


  const IsValid = props.Valid;
  function GetPassengerIcon(): IconDefinition {
    let result: IconDefinition = faMale;
    if (props.Valid.General.IsChild) result = faChild;
    if (props.Valid.General.IsInfant) result = faBaby;
    return result;
  }

  function ToggleBody() {
    props.Toggle(props.Index);
  }

  const [allTabsOpen, setAllTabsOpen] = useState(true);
  const [generaTabOpen, setGeneralTabOpen] = useState(true);
  const [foidTabOpen, setFoidTabOpen] = useState(false);
  const [apisTabOpen, setApisTabOpen] = useState(false);
  const [OtherTabOpen, setOtherTabOpen] = useState(false);
  const [PaymentTabOpen, setPaymentTabOpen] = useState(true);

  function ToggleAllPassengerTabs() {
    const inverted = !allTabsOpen;
    setGeneralTabOpen(inverted);
    setFoidTabOpen(inverted);
    setApisTabOpen(inverted);
    setOtherTabOpen(inverted);
    setAllTabsOpen(inverted);
    setPaymentTabOpen(inverted);
  }

  function OnUpdateApis(apis: FareBookingPassengerApis | undefined) {
    let data: FareBookingInputPassengerModel = { ...passenger.bookingData };
    data.apis = apis;
    OnUpdateData(data);
  }
  function OnUpdateFoid(
    apis: FarebookingFormOfIdentifikationModel | undefined
  ) {
    let data: FareBookingInputPassengerModel = { ...passenger.bookingData };
    data.formOfIdentification = apis;
    OnUpdateData(data);
  }

  function OnUpdateData(bookingData: FareBookingInputPassengerModel) {
    let data = { ...passenger };
    data.bookingData = bookingData;
    props.OnUpdatePassenger(props.Index, data);
  }

  useEffect(() => {
    let data = { ...passenger };

    if (passengerConfig.PassengerGender === "Male") {
      data.bookingData.gender = 1; // Male
    } else if (passengerConfig.PassengerGender === "Female") {
      data.bookingData.gender = 2; // Female
    } else {
      data.bookingData.gender = 0; // Unknown or other default value
    }
    props.OnUpdatePassenger(props.Index, data);
  }, []);
  
  return (
    <div className="card  mb-3">
      <div
        className="card-header card-header-primary  px-3 py-2 d-flex clickme "
        onClick={() => ToggleBody()}
      >
        <h6 className="w-100">
          <span className="float-start">
            <ValidIndicatorIcon
              IsValid={IsValid.Valid && props.paymentValid[`passengerSelection${props.Index + 1}`]}
              Icon={GetPassengerIcon()}
              NoValueSet={false}
            />
            <DisplayBookingPassengerName
              Passenger={passenger}
              ShowIcon={false}
              passengerTypeChanged={passengerTypeChanged}
            />

            {props.Passenger.bookingData.isMainPassenger && (
              <span className="ml-2">(Main passenger)</span>
            )}
          </span>

          <span className="float-end mr-0">
            <FontAwesomeIcon
              icon={props.IsOpen ? faAngleDoubleUp : faAngleDoubleDown}
            />
          </span>
        </h6>
      </div>
      <Collapse isOpen={props.IsOpen}>
        <div className="card-body paxData px-3">
          <div className="row">
            <div className="col-12 mb-2">
              <span className="float-end">
                <button
                  onClick={() => ToggleAllPassengerTabs()}
                  type="button"
                  className="btn btn-sm btn-noborder"
                  title="Expand/Collpase all tabs"
                >
                  <span className="text-muted">
                    {allTabsOpen ? "Collapse" : "Expand"} all tabs
                  </span>
                  &nbsp;&nbsp;
                  <FontAwesomeIcon icon={allTabsOpen ? faEyeSlash : faEye} />
                </button>
              </span>
            </div>
          </div>
          {
            <PaymentDataTab
              setPaymentBOFDetails={props.setPaymentBOFDetails}
              IsOpen={PaymentTabOpen}
              Toggle={() => setPaymentTabOpen(!PaymentTabOpen)}
              paymentBOFDetails={props.paymentBOFDetails}
              setPaymentDataBackend={props.setPaymentDataBackend}
              paymentValid={props.paymentValid[`passengerSelection${props.Index+1}`]}
              paymentDataIndex={props.Index}
              isPerPerson="1"
              PassengersAmount={props.PassengersAmount}
            />
          }

          <BookingPassengerGeneralTab
            Passenger={props.Passenger.bookingData}
            DobLocked={props.Passenger.dateOfBirthLocked}
            OnUpdatePassenger={OnUpdateData}
            Index={props.Index}
            Valid={props.Valid.General}
            IsOpen={generaTabOpen}
            Toggle={() => setGeneralTabOpen(!generaTabOpen)}
            Next={() => {
              setGeneralTabOpen(false);
              setApisTabOpen(true);
            }}
          />

          <BookingPassengerApisTab
            Data={props.Passenger.bookingData.apis}
            OnUpdate={OnUpdateApis}
            Valid={props.Valid.Passport}
            Apis={props.Apisrequirements}
            IsOpen={apisTabOpen}
            Toggle={() => setApisTabOpen(!apisTabOpen)}
            Next={() => {
              setApisTabOpen(false);
              setOtherTabOpen(true);
            }}
            ShowRemoveButton={true}
          />

          <BookingPassengerFoidTab
            Data={props.Passenger.bookingData.formOfIdentification}
            OnUpdate={OnUpdateFoid}
            Valid={props.Valid.Foid}
            IsOpen={foidTabOpen}
            Apis={props.Apisrequirements}
            Toggle={() => setFoidTabOpen(!foidTabOpen)}
            Next={() => {
              setFoidTabOpen(false);
              setApisTabOpen(true);
            }}
          />
          <BookingPassengerOtherTab
            Passenger={props.Passenger.bookingData}
            OnUpdatePassenger={OnUpdateData}
            Index={props.Index}
            Valid={props.Valid.Other}
            IsOpen={OtherTabOpen}
            Toggle={() => setOtherTabOpen(!OtherTabOpen)}
            Next={() => {
              setOtherTabOpen(false);
              if (props.HasNext) {
                props.ExpandNext(props.Index);
              }
            }}
          />
        </div>
      </Collapse>
    </div>
  );
};

interface DisplayBookingPassengerNameProps {
  Passenger: FareBookingPassengerView;
  ShowIcon: boolean;
  passengerTypeChanged: boolean;
}
export const DisplayBookingPassengerName: React.FC<
  DisplayBookingPassengerNameProps
> = (props) => {
  const passengerType: { [key: string]: string } = PassengerJson;
  const { Passenger } = props;

  function GetPassengerDisplayName(): string {
    const passengerTypeKey = Passenger.bookingData.passengerType;
    const isTypeChanged = props.passengerTypeChanged;

    let result: string;

    if (isTypeChanged && passengerTypeKey in passengerType) {
      result = `${passengerType[passengerTypeKey]} ${Passenger.passengerTypeIndex + 1
        }`;
    } else {
      result = `${Passenger.resultPassengerDisplayName} ${Passenger.passengerTypeIndex + 1
        }`;
    }
    return result;
  }

  return <span>{GetPassengerDisplayName()}</span>;
};

import { faEye, faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTimeDisplay } from "components/Shared/DateDisplay";
import { SharedPriceDisplay } from "components/Shared/PriceDisplay";
import { TabbedContent, TabbedContentProps } from "components/Shared/TabbedContent";
import React, { Fragment, useState } from "react";
import { Collapse } from "reactstrap";
import { BookingItemQuoteModel, BookingManagementPassengerQuote, SharedPriceModel } from './../../../WebApiClient';

interface BookingVersionItemDetailsPassengerQuoteProps {
  Data: BookingManagementPassengerQuote[];

}
export const BookingQuotesCard: React.FC<BookingVersionItemDetailsPassengerQuoteProps> = ({ Data }) => {
  const TabContent: TabbedContentProps = {
    EnableJump: true,
    Tabs: Data.map((e, i) => {
      return {
        Component: <QuoteItem data={e} key={"QuteItem" + i} index={i} />,
        Title: `Quote #${i + 1} ${e.passengerType}`,
        Icon: faInfoCircle,
        AmountIndicator: undefined,
        RenderOnlyIfActive: true
      }
    })

  }


  const [checkCurrentTab, setCheckCurrentTab] = useState(0)
  const tabbedView = true;

  return (
    <div className="card">
      <div className="card-header card-header-primary">Quotes <span className="badge badge-primary badge-pill align-top">{Data.length}</span></div>
      <div className="card-body">
        {tabbedView ?
          <TabbedContent {...TabContent} setCheckCurrentTab={setCheckCurrentTab} checkCurrentTab={checkCurrentTab}/> :
          <QuotesTable Data={Data}></QuotesTable>
        }
        {/* <div className="row">
          <div className="col-12">
            {Data.length > 0 ?
              <div className="row">
                {Data.map((quote, index) =>
                  <QuoteItem data={quote} key={index} index={index} />

                )}

              </div>


              :
              <span>
                No data available.
              </span>
            }

          </div>
        </div> */}

      </div>

    </div>
  );


}

export default BookingQuotesCard;

const QuoteItem: React.FC<{ data: BookingManagementPassengerQuote, index: number }> = ({ data, index }) => {
  const { externalReference, fareQuote, passengerType, payment, quoteSegmentIDs, segmentNumbers, ticketInfos } = data;

  return (
    <div className="card">
      {/* <div className="card-header">Quote #{(index + 1).toString()}</div> */}

      <div className="card-body">
        <div className="row">
          <div className="col-12 mb-2">
            <div className="card">
              <div className="card-header card-header-primary">General</div>
              <div className="card-body">
                <table className="table table-sm table-striped table-hover table-bordered">
                  <tbody>
                    <tr>
                      <td className="font-weight-bold">Passenger type</td>
                      <td>{passengerType}</td>
                    </tr>
                    {quoteSegmentIDs.length > 0 &&
                      <tr>
                        <td className="font-weight-bold">Quote segment ID's</td>
                        <td>{quoteSegmentIDs.join(",")}</td>
                      </tr>
                    }
                    {segmentNumbers.length > 0 &&
                      <tr>
                        <td className="font-weight-bold">Segment numbers</td>
                        <td>{segmentNumbers.join(",")}</td>
                      </tr>
                    }
                    {externalReference.length > 0 &&
                      <tr>
                        <td className="font-weight-bold">External reference</td>
                        <td>{externalReference}</td>
                      </tr>
                    }


                  </tbody>
                </table>
              </div>
            </div>

          </div>
          {fareQuote &&
            <div className="col-12 mb-2">
              <div className="card">
                <div className="card-header card-header-primary">Fare Quote</div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <table className="table table-sm table-striped table-hover table-bordered">
                        <tbody>
                          {fareQuote.equivalentBaseFare &&
                            <tr>
                              <td className="font-weight-bold">Equiv. Base fare</td>
                              <td><SharedPriceDisplay price={fareQuote.equivalentBaseFare} /></td>
                            </tr>
                          }
                          {fareQuote.equivalentTotalFee &&
                            <tr>
                              <td className="font-weight-bold">Equiv. Total fee</td>
                              <td><SharedPriceDisplay price={fareQuote.equivalentTotalFee} /></td>
                            </tr>
                          }
                          {fareQuote.equivalentTotalSurcharge &&
                            <tr>
                              <td className="font-weight-bold">Equiv. Total surcharge</td>
                              <td><SharedPriceDisplay price={fareQuote.equivalentTotalSurcharge} /></td>
                            </tr>
                          }
                          {fareQuote.equivalentTotalTax &&
                            <tr>
                              <td className="font-weight-bold">Equiv. Total tax</td>
                              <td><SharedPriceDisplay price={fareQuote.equivalentTotalTax} /></td>
                            </tr>
                          }
                          {fareQuote.fareCalculation.length > 0 &&
                            <tr>
                              <td className="font-weight-bold">Calculation</td>
                              <td>{fareQuote.fareCalculation}</td>
                            </tr>
                          }

                        </tbody>
                      </table>

                    </div>
                    <div className="col-12">
                      {fareQuote.taxDetails.length > 0 &&
                        <div className="card">
                          <div className="card-header card-header-primary">Taxes</div>
                          <div className="card-body">
                            <table className="table table-sm table-striped table-hover table-bordered">
                              <thead>
                                <tr>
                                  <th className="font-weight-bold">Action</th>
                                  <th className="font-weight-bold">Price</th>
                                  <th className="font-weight-bold">Type</th>
                                </tr>
                              </thead>
                              <tbody>
                                {fareQuote.taxDetails.map((tax, taxIndex) =>
                                  <tr key={"tax" + taxIndex}>
                                    <td>{tax.action}</td>
                                    <td><SharedPriceDisplay price={tax.price} /></td>
                                    <td>{tax.type}</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {payment &&
            <div className="col-12 mb-2">
              <div className="card">
                <div className="card-header card-header-primary">Payment</div>
                <div className="card-body">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td className="font-weight-bold">Provider</td>
                        <td>{payment.paymentProvider}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Type</td>
                        <td>{payment.paymentType}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Stored ID</td>
                        <td>{payment.storedPaymentID}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          }
          {ticketInfos.map((ticketInfo, index) =>

            <div className="col-12" key={"ticketInfo" + index}>
              <div className="card">
                <div className="card-header">Ticket info</div>
                <div className="card-body">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td className="font-weight-bold">Commission</td>
                        <td>{ticketInfo.commission}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Designator</td>
                        <td>{ticketInfo.designator}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Endorsement</td>
                        <td>{ticketInfo.endorsement}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Fare base</td>
                        <td>{ticketInfo.farebase}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">FOP</td>
                        <td>{ticketInfo.fop}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Free baggage</td>
                        <td>{ticketInfo.freeBaggageAllowance}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">NVA</td>
                        <td>{ticketInfo.nva}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">NVB</td>
                        <td>{ticketInfo.nvb}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Stop indicator</td>
                        <td>{ticketInfo.stopIndicator}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">TTL</td>
                        <td><DateTimeDisplay date={ticketInfo.tickettingTimeLimit} /></td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Tour code</td>
                        <td>{ticketInfo.tourcode}</td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          )}



        </div>

      </div>
    </div>
  );


}



const QuotesTable: React.FC<BookingVersionItemDetailsPassengerQuoteProps> = ({ Data }) => {

  return <table className="table table-sm  table-bordered">
    <thead>
      <tr>
        <th>Type</th>
        <th>Quote</th>
        <th>Payment</th>
        <th>Ticket</th>
      </tr>

    </thead>
    <tbody>
      {Data.map((quote, index) =>
        <QuotesTableRow key={"quote"+index} Data={quote}></QuotesTableRow>
      )}
    </tbody>
  </table>
}

const QuotesTableRow: React.FC<{ Data: BookingManagementPassengerQuote }> = ({ Data }) => {
  const { externalReference, fareQuote, passengerType, payment, quoteSegmentIDs, segmentNumbers, ticketInfos } = Data;
  return <tr>
    <td>{passengerType}</td>
    <td>{fareQuote && <QuotesTableCell Data={fareQuote} />}</td>
    <td>{payment &&
      <Fragment>
        {payment.paymentType} {payment.paymentProvider}
      </Fragment>
    }</td>
    <td></td>
  </tr>
}
const QuotesTableCell: React.FC<{ Data: BookingItemQuoteModel }> = ({ Data }) => {
  const { equivalentBaseFare, equivalentTotalFee, equivalentTotalSurcharge, equivalentTotalTax, fareCalculation, taxDetails } = Data;
  const [open, setOpen] = useState(false);
  function GetTotal(): SharedPriceModel {
    let result = 0;
    if (equivalentBaseFare) {
      result += equivalentBaseFare.value;
    }
    if (equivalentTotalTax) {
      result += equivalentTotalTax.value;
    }
    if (equivalentTotalSurcharge) {
      result += equivalentTotalSurcharge.value;
    }
    if (equivalentTotalFee) {
      result += equivalentTotalFee.value;
    }
    const price: SharedPriceModel = {
      currencyCode: equivalentBaseFare?.currencyCode ?? "??",
      isIATA: false,
      isIATASpecified: false,
      value: result,
      valueSpecified: true
    }
    return price;
  }
  const total = GetTotal();
  return <td>
    <button className="btn btn-sm btn-primary" onClick={() => { setOpen(!open) }}>
      <SharedPriceDisplay price={total} />{` `}
      <FontAwesomeIcon icon={faEye} /></button>
    <Collapse isOpen={open}>

      <table className="table table-sm table-hover table-bordered">
        <thead>
          <tr>

            <th className="fw-bold">Base fare</th>
            <th className="fw-bold">Total fee</th>
            <th className="fw-bold">Total surcharge</th>
            <th className="fw-bold">Total Tax</th>
            <th className="fw-bold">Taxes</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td>
              <SharedPriceDisplay price={equivalentBaseFare} />
            </td>
            <td>
              <SharedPriceDisplay price={equivalentTotalFee} />
            </td>
            <td>
              <SharedPriceDisplay price={equivalentTotalSurcharge} />
            </td>
            <td>
              <SharedPriceDisplay price={equivalentTotalTax} />
            </td>
            <td>
              <table className="table table-sm  table-hover table-bordered">
                <thead>

                  <th className="fw-bold">Action</th>
                  <th className="fw-bold">Price</th>
                  <th className="fw-bold">Type</th>
                </thead>
              </table>
              <tbody>
                {taxDetails &&
                  <Fragment>
                    {taxDetails.map((tax, taxIndex) =>
                      <tr key={"taxDetails"+taxIndex}>
                        <td>{tax.action}</td>
                        <td>
                          <SharedPriceDisplay price={tax.price} />
                        </td>
                        <td>
                          {tax.type}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                }
              </tbody>
            </td>
          </tr>
        </tbody>
      </table>
    </Collapse>


  </td>

}
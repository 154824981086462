import { faAngleDoubleDown, faAngleDoubleRight, faAngleDoubleUp, faCalendarAlt, faPlaneArrival, faPlaneDeparture, faTimes, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CalculationResultDisplay } from "components/Fare/CalculationDebugModal";
import { PnrSegmentsCard } from "components/PNR/Pnr.SegmentsCard";
import { AirlineLogo } from "components/Shared/AirlineLogo";
import { TabbedContent, TabbedContentProps, TabProps } from "components/Shared/TabbedContent";
import React, { useEffect, useState } from "react";
import { Card, Collapse, ListGroup, ListGroupItem, Spinner } from "reactstrap";
import { AgentApiURL } from "Constants";
import { ApiCredentialsOptions, BookingItemPassengerModel, BookingManagementLeg, CalculationResultModel, PnrResponseSegmentModel, PublicServicesClient } from "WebApiClient";
import { BookingSegmentIdentifier } from "../types/BookingDetailsTypes";
import { AirportType, ISegmentState, dropdownItem } from "components/FareSearch/types/FareSearchTypes";
import _ from 'lodash';
import { ArrowContainer, PopoverState, Popover, PopoverPosition } from "react-tiny-popover";
import nextElementFocus from "components/FareSearch/component/FlightDetails/nextElementFocus";
import { ValidSegmentAirport } from "components/FareSearch/Business/ValidateSearch";
import GuessDate, { GuessDateResult } from "components/FareSearch/Business/GuessDate";
import { useDateFormat, useFormatDate } from "hooks/useLocalization";
import { ActiveModifiers, Matcher } from "react-day-picker";
import { Calendar } from "@contentful/f36-components";
import { useDispatch, useSelector } from "react-redux";
import { booking_Redirect_Reducer } from "rootExports/BookingRedirectReducer";
import { useLocation, useNavigate } from "react-router-dom";
import { State } from "rootExports/rootReducer";
import { useBookingClient, useReportingClient } from "hooks/useHttpClient";
import moment from "moment";
import { airline_Code_Reducer } from "rootExports/AirlineCodeReducer";
import { Session_Reducer_HideCustomerWaitScreen, Session_Reducer_HideGeneralWaitScreen, Session_Reducer_PushTransaction } from "rootExports/SessionReducer";
import { useSessionHandler } from "hooks/useSessionHandler";
import { toast } from "react-toastify";
import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";

interface BookingVersionItemDetailsBookedFlightProps {
    Data: BookingManagementLeg[]
    OnCancelSegment?: (segments: BookingSegmentIdentifier[]) => void;
    OnAddSegment?: () => void;
    passengerData: BookingItemPassengerModel[]
    fileKey: String
    OnSync?: () => void | any;
    refresh?: () => void | any;
}

const BookedFlightSegmentsCard: React.FC<BookingVersionItemDetailsBookedFlightProps> = props => {
    const enableSelection = (props.OnCancelSegment !== undefined)
    const [selectedSegments, setSelectedSegments] = useState<BookingSegmentIdentifier[]>([]);
    const hasSelectedElements = selectedSegments.length > 0;

    // function OnToggleSelection(selection: BookingSegmentIdentifier) {
    //     const elemAt = selectedSegments.findIndex(e => e.LegIndex === selection.LegIndex && e.SegmentIndex === selection.SegmentIndex);
    //     let contains = elemAt !== -1;
    //     let arrCopy = [...selectedSegments];
    //     if (contains) {
    //         arrCopy.splice(elemAt, 1);
    //     }
    //     else {
    //         arrCopy.push(selection);
    //     }
    //     setSelectedSegments(arrCopy);
    // }

    function OnCancel() {
        if (props.OnCancelSegment) {
            props.OnCancelSegment(selectedSegments)
        }
    }

    function GetTabs(): TabProps[] {
        let result: TabProps[] = [];

        result = props.Data.map((leg, index) => {

            const dateTime = new Date(`${leg.segments[0].departureDate}`);
            const segmentDate = dateTime?.toLocaleDateString('en-GB').replace(/\//g, '-');
            const maxSegmIndex = leg.segments.length - 1;
            const tabTitle = leg.segments.length > 0 ? `${leg.segments[0].departure.iata} - ${leg.segments[maxSegmIndex].arrival.iata}` : "Unkown";
            const firstDep = leg.segments.length > 0 ? `${leg.segments[0].departure.iata}` : "Unkown";
            const lastArr = leg.segments.length > 0 ? `${leg.segments[maxSegmIndex].arrival.iata}` : "Unkown";
            const tab: TabProps = {
                Component: <LegItem passengerData={props?.passengerData} data={leg} fullData={props.Data} index={index} title={tabTitle} fileKey={props.fileKey} OnSync={props.OnSync} refresh={props.refresh} />,
                Title: <span><FontAwesomeIcon icon={faPlaneDeparture} /> {firstDep} - <FontAwesomeIcon icon={faPlaneArrival} /> {lastArr} <span style={{ fontSize: "0.7em" }} className="align-top badge badge-pill badge-dark"> <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}><span>{leg.segments.length} segments</span><span>{segmentDate}</span></div> </span></span>,
                AmountIndicator: undefined

            }
            return tab;
        })
        return result;
    }

    const DetailsTabContent: TabbedContentProps = {
        EnableJump: true,
        Tabs: GetTabs()
    }


    const [checkCurrentTab, setCheckCurrentTab] = useState(0)

    return (
        <div className="card">
            <div className="card-header card-header-primary">Flight</div>
            <div className="card-body">
                <div className="row">
                    {props.Data.length > 0 ?
                        <div className="col-12 position-relative">
                            <TabbedContent {...DetailsTabContent} setCheckCurrentTab={setCheckCurrentTab} checkCurrentTab={checkCurrentTab}/>
                        </div>
                        : <div className="col-12">
                            No data available
                        </div>
                    }
                </div>
            </div>
            {enableSelection &&
                <div className="card-footer">
                    {props.OnCancelSegment &&
                        <button disabled={!hasSelectedElements} className="btn btn-primary float-end" onClick={OnCancel}>Cancel selection</button>
                    }
                </div>
            }
        </div>
    );
}

export default BookedFlightSegmentsCard;

const LegItem: React.FC<{ data: BookingManagementLeg, index: number, title: string, fullData: any, passengerData: BookingItemPassengerModel[], fileKey: String, OnSync: any, refresh: any }> = ({ data, title, passengerData, fileKey, OnSync, refresh }) => {
    const { cabinClass, carrier, pointOfTurnaround, segments, calculationResults } = data;
    const [segment, setSegment] = useState<ISegmentState>({
        DepartureInput: "",
        ArrivalInput: "",
        CabinClasses: {
            Economy: true,
            Business: false,
            First: false,
            PremiumEconomy: false
        },
        DateInput: undefined,
        Radius: 0
    })
    const [selectedSegment, setSelectedSegment] = useState<number>(0)
    const getDatess = useReportingClient();
    function OnChangeNew(value: Date | undefined) {
        let newSegment: ISegmentState = { ...segment, DateInput: value };
        setSegment(newSegment);
    }
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    function redirectTo(checkBtnSelected: string) {
        const currentPath = location.pathname;
        const newPath = currentPath.replace("/bookingmanager", "/availfares");
        if (currentPath !== newPath) {
            navigate(newPath, { replace: true });
        }
        dispatch(booking_Redirect_Reducer({ bookingRedirect: true, checkActiveBtnClicked: checkBtnSelected, bookingSegmentsData: segments, bookingSegmentsDataCustom: segment, bookingSegmentPassengerData: passengerData, recordLocator: fileKey, selectedSegment: selectedSegment }))
    }
    const bookedStyle1 = {
        border: "#90EE90",
        backgroundColor: "#90EE90",
    };
    const bookedStyle2 = { border: "2px solid blue" };
    const bookedStyle3 = {
        border: "2px solid blue",
        backgroundColor: "#90EE90",
    };
    //container hide/show settings
    var otherSettings: string = useSelector((state: State) => {
        if (
            state.Session.FrontendSettings.otherSettingsAsKeyValueText == null ||
            state.Session.FrontendSettings.otherSettingsAsKeyValueText.trim() === ""
        ) {
            return state.Session.FrontendSettings.otherSettingsAsKeyValueText ===
                "menuPosition=L\nshowFareHeader=Off"
                ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None\n[]\nfalse\nfalse"
                : 'menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}showFareHeader=OffshowSmartCalender=None\n[]\nfalse\nfalse\n{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}0{"PassengerGender":"Unselected","PassengerDOB":"No"}showHotelTab=All{"Container":"#BRANCHCHANGE#"}showCabinContainer=None';
        }
        return state.Session.FrontendSettings.otherSettingsAsKeyValueText;
    });
    const isAdmin = useSelector((state: State) => state.Session.isAdmin);
    const sessionHandler = useSessionHandler();
    const airlineData = useSelector((state: any) => state.airlineSession.value);
    let settingArr = otherSettings.split("\n");
    let fieldContainer: string[];
    let smartCalender = (settingArr[2] !== undefined && settingArr[2].length <= 15) ? "showSmartCalender=None/n[]/nfalse/nfalse"
        : settingArr[2];
    let calender = smartCalender.split("/n");
    let showSmartCalender = calender[0];
    let airline = JSON.parse(calender[1]);
    let flightDate = JSON.parse(calender[2]);
    let allotment = JSON.parse(calender[3]);
    const [loading, setLoading] = useState(false);
    const [errorOccured, setErrorOccured] = useState(false);
    const [booked1, setBooked1] = React.useState(flightDate);
    const [booked2, setBooked2] = React.useState(allotment);
    const [destination, setDestination] = useState("");
    const [origin, setOrigin] = useState("");
    const [bookedDays1, setBookedDays1] = useState();
    const [bookedDays2, setBookedDays2] = useState();
    const [bookedDays3, setBookedDays3] = useState();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isFind, setIsFind] = useState(false);
    const Client = useBookingClient();

    const [bookingResponse, setBookingResponse] = useState<any>(undefined);

    useEffect(() => {
        if (airline.length) {
            dispatch(airline_Code_Reducer(airline))
        }
    }, [])

    if (settingArr[7]) {
        try {
            fieldContainer = JSON.parse(settingArr[7]).Container.split("#").filter((item: any) => item !== "");
        } catch (error) {
            // Provide a default value or error indicator
            fieldContainer = [];
        }
    } else {
        // Provide a default value or error indicator
        fieldContainer = [];
    }
    const hasRebooking = _.includes(fieldContainer, "REBOOKING");
    if (origin !== `[${segments[0]?.departure.cityCode}] - ${segments[0]?.departure.cityName} - ${segments[0]?.departure.name}`) {
        setOrigin(`[${segments[0]?.departure.cityCode}] - ${segments[0]?.departure.cityName} - ${segments[0]?.departure.name}`);
        setIsFind(true);
    }

    if (destination !== `[${segments[segments?.length - 1]?.arrival.cityCode}] - ${segments[segments?.length - 1]?.arrival.cityName} - ${segments[segments?.length - 1]?.arrival.name}`) {
        setDestination(`[${segments[segments?.length - 1]?.arrival.cityCode}] - ${segments[segments?.length - 1]?.arrival.cityName} - ${segments[segments?.length - 1]?.arrival.name}`);
        setIsFind(true);
    }

    function createBookingIdentifier() {
        const { cartID, bookingItemID } = segments[selectedSegment];
        return {
            cartId: cartID,
            bookingId: bookingItemID,
            emailIds: null,
            description: null,
            templateName: null,
            passengerSelection: null,
            segmentSelection: null,
            emdSelection: null,
        };
    }

    const getDates = () => {
        var newOrigin = "";
        var newDestination = "";

        if (origin.includes("[")) {
            newOrigin = origin.slice(1, 4);
        } else if (origin.length >= 3) {
            newOrigin = origin.slice(0, 3);
        } else {
            newOrigin = origin;
        }

        if (destination.includes("[")) {
            newDestination = destination.slice(1, 4);
        } else if (destination.length >= 3) {
            newDestination = destination.slice(0, 3);
        } else {
            newDestination = destination;
        }

        let payloadA = {
            origin: newOrigin,
            destination: newDestination,
            airline: airlineData[0]?.value,
            dataSource: "F",
        };

        if (flightDate) {
            getDatess.getPossibleDepartureDates(payloadA).then((response: any) => {
                flightDates(response.result.possibleDepartureDates);
            }).catch((err: any) => {
                console.log(err);
            }).finally(() => { });
        }
    };

    const flightDates = (dates: any) => {
        let dateArr: any = [];
        dates.map((date: any) => {
            let year = date.slice(0, 4);
            let month = date.slice(4, 6);
            let day = date.slice(6, 8);
            new Date(year + "/" + month + "/" + day);
            dateArr.push(new Date(year + "/" + month + "/" + day));
            return dateArr;
        });
        // setHdates(dateArr);
        setBookedDays1(dateArr);
        setIsFind(false);
    };

    const cancelLegCheck = () => {
        // Split the input string into two parts
        const [departureCode, arrivalCode] = title.split(' - ');

        // Check if isDepPresent and isArrPresent are present in str1 and str2 respectively
        const isDepPresent = _.includes(segment?.DepartureInput, departureCode);
        const isArrPresent = _.includes(segment?.ArrivalInput, arrivalCode);

        //date check
        let momentDate1 = moment(data.segments[0].departureDate);
        let momentDate2 = moment(segment.DateInput);

        return isDepPresent && isArrPresent && (momentDate1.isSame(momentDate2, 'day') && momentDate1.isSame(momentDate2, 'month') && momentDate1.isSame(momentDate2, 'year'))
    };

    async function handleCancelSegments(model: any) {
        setLoading(true);
        try {
            var response = await Client.cancelSegments(model);
            console.log(response);
            dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
            setBookingResponse(response)
            if (response.result) {
                if (response.result.updatedBooking) {
                    setErrorOccured(false);
                    // props.OnUpdate(response.result.updatedBooking);
                } else {
                    if (response.result.validationResult.isValid) {
                        setErrorOccured(true);
                    }
                }
            } else {
                setErrorOccured(true);
            }
            if (response.responseMetaData.errorOccured == false) {
                toast.success("Leg was cancelled");
                setErrorOccured(false);
            }
            if (response.responseMetaData.errorOccured == true) {
                toast.error("An error occurred while canceling leg");
                setErrorOccured(true);
            }
        } catch (error) {
            console.log(error);
            setErrorOccured(true);
        } finally {
            dispatch(Session_Reducer_HideCustomerWaitScreen());
            dispatch(Session_Reducer_HideGeneralWaitScreen());
            setLoading(false);
            sessionHandler.RefreshSession();
            refresh();
            OnSync();
            setTimeout(() => {
                refresh();
                OnSync();
            }, 5000);
        }
    }

    const handleConfirm = () => {
        const model = {
            segmentSelection: segments.map((segment: PnrResponseSegmentModel) => segment.segmentNumber),
            bookingIdentifier: createBookingIdentifier(),
        };
        handleCancelSegments(model);
    };

    return (
        <div className="card" style={{ position: "unset" }}>
            <div className="card-body">
                <div className="col-12">
                    <div className="row">
                        {/* {
                            hasRebooking && <div className="text-end">
                                <button onClick={() => redirectTo('Add')} className=" btn btn-primary position-absolute rebookAddBtn" style={{ top: "-4.8px", right: "1.8%" }}>Add new leg</button>
                            </div>
                        } */}
                        <div className="col-12 mb-2 text-black ">
                            <h5 className="card-title">
                                {title}
                                <span className="float-end">
                                    <AirlineLogo IsAlliance={false} PlatingCarrier={carrier.code} PlatingCarrierName={carrier.hint} />
                                </span>
                            </h5>
                        </div>
                        <div className="col-12 text-black d-flex justify-content-between">
                            <div className="col-2">
                                <p>Carrier: {carrier.hint} ({carrier.code})</p>
                                {cabinClass.length > 0 &&
                                    <p>Cabin class: {cabinClass}</p>
                                }
                                {pointOfTurnaround.length > 0 &&
                                    <p>Point of turnaround: {pointOfTurnaround}</p>
                                }
                            </div>
                            {
                                hasRebooking && <div className="col-10 text-black text-end d-flex gap-2 justify-content-end mb-2" style={{ flexDirection: 'column' }}>
                                    <div className="d-flex justify-content-end align-items-end gap-2">
                                        <div style={{ width: '25%' }}>
                                            <AirportInput Segment={segment}
                                                UpdateSegment={setSegment} AirportType={AirportType.Departure} tabIndex={1} />
                                        </div>
                                        <div style={{ width: '25%' }}>
                                            <AirportInput Segment={segment}
                                                UpdateSegment={setSegment} AirportType={AirportType.Arrival} tabIndex={2} />
                                        </div>
                                        <div style={{ width: '24%' }}>


                                            {showSmartCalender !== "showSmartCalender=None" &&
                                                (showSmartCalender == "showSmartCalender=All" || isAdmin) && (booked1 || booked2) &&
                                                airlineData.length ? (
                                                <div onFocus={isFind ? getDates : undefined} onClick={isFind ? getDates : undefined}
                                                >
                                                    <DateInput
                                                        Value={segment.DateInput}
                                                        OnDateChange={OnChangeNew}
                                                        InputID="segment0_date"
                                                        // SelectNextInput={SelectNext}
                                                        // MaxDate={maxDate()}
                                                        // MinDate={minDate()}
                                                        InputGroup={true}
                                                        IsDob={false}
                                                        booked1={booked1}
                                                        booked2={booked2}
                                                        bookedDays1={bookedDays1}
                                                        bookedDays2={bookedDays2}
                                                        bookedDays3={bookedDays3}
                                                        bookedStyle1={bookedStyle1}
                                                        bookedStyle2={bookedStyle2}
                                                        bookedStyle3={bookedStyle3}
                                                        tabIndex={3}
                                                        segment={segments[0]?.departureDate}
                                                    />
                                                </div>
                                            ) : (
                                                <DateInput
                                                    Value={segment.DateInput}
                                                    OnDateChange={OnChangeNew}
                                                    InputID="segment0_date"
                                                    InputGroup={true}
                                                    IsDob={false}
                                                    tabIndex={3}
                                                    segment={segments[0]?.departureDate}
                                                />
                                            )}

                                            {/* <DateInput InputGroup={true} IsDob={false} InputID="segment0_date" OnDateChange={OnChangeNew} Value={segment.DateInput} tabIndex={3} /> */}

                                        </div>

                                        <button
                                            style={{ width: "120px" }}
                                            disabled={
                                                (segment?.DepartureInput === "" || segment?.ArrivalInput === "" || segment?.DateInput === undefined) ||
                                                !cancelLegCheck()
                                            }
                                            className="btn btn-primary"
                                            tabIndex={4}
                                            onClick={() => {
                                                if (!((segment?.DepartureInput === "" || segment?.ArrivalInput === "" || segment?.DateInput === undefined) || !cancelLegCheck())) {
                                                    handleConfirm();
                                                }
                                            }}
                                        >
                                            Cancel
                                        </button>
                                        {/* <ConfirmationModal
                                            show={showModal}
                                            onConfirm={handleConfirm}
                                            onCancel={handleCancel}
                                            loading={loading}
                                            message="Are you sure you want to cancel it?"
                                        /> */}
                                        {errorOccured && (
                                            <ErrorDisplay
                                                data={
                                                    bookingResponse?.responseMetaData
                                                }
                                            />
                                        )}
                                    </div>
                                    <div>
                                        <button disabled={(segment?.DepartureInput == "" && segment?.ArrivalInput == '' && segment?.DateInput == undefined)} onClick={() => redirectTo('Rebook')} className="btn btn-primary" style={{ width: '120px' }}>Rebook</button>
                                    </div>
                                </div>
                            }
                        </div>
                        {calculationResults.length > 0 &&
                            <div className="col-12 mb-2">
                                <LegCalculationsItem calculations={calculationResults} />
                            </div>
                        }
                        <div className="col-12">
                            <PnrSegmentsCard data={segments} setSelectedSegment={setSelectedSegment} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const LegCalculationsItem: React.FC<{ calculations: CalculationResultModel[] }> = ({ calculations }) => {
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);
    return <div className="card">
        <div className="card-header card-header-primary clickme" onClick={toggle}>
            Calculations <span className="badge badge-primary badge-pill align-top">{calculations.length} </span><span className="float-end"><FontAwesomeIcon icon={open ? faAngleDoubleDown : faAngleDoubleUp} /></span>
        </div>
        <Collapse isOpen={open}>
            <div className="card-body card-body-secondary">
                <div className="row">
                    {calculations.map((calc, i) =>
                        <div key={"calc" + i} className="col-12 mb-2">
                            <CalculationResultDisplay data={calc} index={i} />
                            {/* <CalculationItem calculation={calc} index={i+1}/> */}
                        </div>
                    )}
                </div>
            </div>
        </Collapse>
    </div>
}
interface OwnProps {
    SegmentIndex?: number;
    Segment?: ISegmentState;
    AirportType?: AirportType;
    UpdateSegment: (segment: ISegmentState | any) => void | any
    // OnSelectNextElem(type: AirportType): void;
    departureCollector?: any;
    setdepartureCollector?: any;
    arrivalCollector?: any;
    isInValidMonth?: boolean;
    reference?: any;
    tabIndex: number;
}
const AirportInput: React.FC<OwnProps> = props => {
    const debug = false;
    const segment: any = props.Segment;
    const isDeparture: boolean = props.AirportType === AirportType.Departure;
    const PrependIcon: JSX.Element = isDeparture ? <FontAwesomeIcon icon={faPlaneDeparture} /> : <FontAwesomeIcon icon={faPlaneArrival} />;
    const IconTitle: string = isDeparture ? "Outbound" : "Inbound";
    const InputID: string = `segment${props.SegmentIndex}_airport${props.AirportType}`;
    const InputAutoFocus: boolean = isDeparture && props.SegmentIndex === 0;
    const isInValidMonth = props.isInValidMonth;
    let InputValue: any = isDeparture ? segment.DepartureInput : segment.ArrivalInput;
    const [InputActive, setInputActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState<dropdownItem[]>([])
    const [activeAutoCompleteItem, setActiveAutoCompleteItem] = useState(0);
    let departureAirportValue = ""
    const [depAirportValueDisplay, setDepAirportValueDisplay] = useState(false);
    var isNativeSearch: boolean = false;

    function GetInputValidLabel(): string {
        const isValid = ValidSegmentAirport(InputValue);
        if (isValid) return "is-valid"
        return "is-invalid";
    }


    // is-invalid
    function stringSeparator(val: any) {
        isNativeSearch = true
        let str = `${val}`;
        let depString = str.slice(0, 3);
        let arrString = str.slice(3, 6)
        let dayString = str.slice(6, 8);
        let monthString = str.slice(8, 10);
        if (depString.length > 2 && (typeof depString === 'string')) {
            props.setdepartureCollector((prev: any) => ({
                ...prev,
                departureL: depString
            }))
        }
        if (arrString.length > 2 && (typeof arrString === 'string')) {
            props.setdepartureCollector((prev: any) => ({
                ...prev,
                arrivalL: arrString
            }))
        }
        if (dayString) {
            props.setdepartureCollector((prev: any) => ({
                ...prev,
                DayL: Number(dayString)
            }))
        }
        if (monthString) {
            props.setdepartureCollector((prev: any) => ({
                ...prev,
                MonthL: Number(monthString)
            }))
        }
    }
    function OnSearch(val: string) {

        departureAirportValue = val
        if (departureAirportValue.length > 6) {
            setDepAirportValueDisplay(true);
        }

        if (departureAirportValue.length < 6) {
            setDepAirportValueDisplay(false);
        }

        if ((/^(?=.*\d.*\d.*\d.*\d)[A-Za-z\d]{6,}$/.test(val))) { //more 3 char more than 3 number
            stringSeparator(val)
        }

        if (isDeparture) {
            const segment: ISegmentState | any = { ...props.Segment, DepartureInput: val };
            props.UpdateSegment(segment);
        } else {
            const segment: ISegmentState | any = { ...props.Segment, ArrivalInput: val };
            props.UpdateSegment(segment);
        }

        if (val.length > 2) {
            setItems([]);
            setIsLoading(true);
            const client: PublicServicesClient = new PublicServicesClient(new ApiCredentialsOptions(AgentApiURL, ""))
            client.getAirportsByPrefix(val).then((result) => {
                if (result.length > 0) {
                    const map: dropdownItem[] = [];
                    result.forEach(e => {
                        const text = `[${e.iata}] - ${e.cityName} - ${e.name}`
                        map.push({ value: e.iata!, text: text, image: e.countryCode! })
                    })
                    if (!isNativeSearch) {
                        setItems(map);
                    }
                }
                else {
                    setItems([]);
                }
            }).catch(() => {
                setItems([]);
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }
    const AirportAutoCompleteOptions = items.map((airport, index) =>
        <ListGroupItem
            className={`btn-sm`}
            key={`ac_${props.AirportType}_${props.SegmentIndex}_${index}`}
            onMouseEnter={() => setActiveAutoCompleteItem(index)}
            active={activeAutoCompleteItem === index}
            onClick={() => { OnAutocompleteClick(index) }}
            tag="button"
            action>
            <img src={`/images/flags/${airport.image.toLowerCase()}.png`} alt="airport" />{` `}
            <span>{airport.text}</span>
            {/* {activeAutoCompleteItem === index &&
                <span className="small float-end">{"  "}<kbd>enter</kbd>,<kbd>tab</kbd> </span>
            } */}
        </ListGroupItem>
    );
    function OnAutocompleteClick(index: number) {
        OnSelectAutocompleteItem();
    }
    function AirportLabel(): string {
        if (isLoading) {
            return "loading..."
        }
        if (InputValue.length < 2) {
            return "Type to see results..."
        }
        if (items.length > 0) {
            return `${items.length.toString()} Airports found`;
        }
        return "Type to see results...";
    }
    function ToolTipContent(popoverState: PopoverState): JSX.Element {
        return <ArrowContainer position={popoverState.position} childRect={popoverState.childRect} popoverRect={popoverState.popoverRect} arrowColor="black" arrowSize={5}>
            <Card className={depAirportValueDisplay ? 'hideAirportList' : ''} >
                <span className="text-center ">{AirportLabel()}</span>
                {/* <CardBody className="small"> */}
                {items.length > 0 &&
                    <ListGroup flush={true}>
                        {AirportAutoCompleteOptions}
                    </ListGroup>
                }
                {/* </CardBody> */}
            </Card>
        </ArrowContainer>
    }
    function OnFocus(e: React.FocusEvent<HTMLInputElement>) {
        e.stopPropagation();
        if (debug) {
            console.log(`Focus: ${isDeparture ? "Departure" : "Arrival"}`)
        }
        setInputActive(true);
        e.target.select();
    }
    function OnKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        const key = event.which;
        if (key === 40 || key === 38 || key === 13 || key === 9) {
            if (!(key === 13 && event.ctrlKey)) {
                // event.preventDefault();
                event.stopPropagation();
                if (key === 40) { //down arrow             
                    if (activeAutoCompleteItem < items.length - 1) {
                        setActiveAutoCompleteItem(activeAutoCompleteItem + 1)
                    }
                }
                if (key === 38) { //up arrow                                       
                    if (activeAutoCompleteItem > 0) {
                        setActiveAutoCompleteItem(activeAutoCompleteItem - 1)
                    }
                }
                if (!event.shiftKey && event.keyCode) {
                    if (key === 13 || key === 9) { // tab/enter
                        OnSelectAutocompleteItem();
                    }
                }
                // if (event.shiftKey && event.keyCode) {
                //     if (key === 9) { // tab/enter
                //         setInputActive(false);
                //     }
                // }
            }
        }
    }
    function OnPopoverClickOutside() {
        if (debug) {
            console.log(`OnPopoverClickOutside: ${isDeparture ? "Departure" : "Arrival"}`)
        }
        setInputActive(false);
    }
    function OnSelectAutocompleteItem() {
        if (debug) {
            console.log(`OnSelectAutocompleteItem: ${isDeparture ? "Departure" : "Arrival"}`)
        }

        if (items[activeAutoCompleteItem] !== undefined) {
            const autoCompleteItem = items[activeAutoCompleteItem];
            const value = autoCompleteItem.text;
            OnValueChanged(value);
        }
        setInputActive(false);
        setTimeout(function () { // set timeout to avoid triggering PopoverClick outside event on next input element
            // props.OnSelectNextElem(props.AirportType);
        }, 100);
    }
    function OnValueChanged(value: string) {
        if (debug) {
            console.log(`OnValueChanged: ${isDeparture ? "Departure" : "Arrival"}`)
        }

        if (isDeparture) {
            const segment: ISegmentState | any = { ...props.Segment, DepartureInput: value };
            props.UpdateSegment(segment);
        }
        else {
            const segment: ISegmentState | any = { ...props.Segment, ArrivalInput: value };
            props.UpdateSegment(segment);
        }
    }
    const OffClose = () => {
        setTimeout(function () {
            setInputActive(false)
        }, 300);
        if (InputValue?.length != 0 && GetInputValidLabel()) {
            OnSelectAutocompleteItem();
        }
    }
    return (
        <React.Fragment>
            <Popover onClickOutside={OnPopoverClickOutside} positions={['bottom', 'right', 'left', 'top']} isOpen={InputActive} containerStyle={{ zIndex: "10000" }} content={(popoverState: PopoverState) => (ToolTipContent(popoverState))}>

                <div className="input-group">
                    <span className="input-group-text" title={`${IconTitle} Airport`}>
                        {PrependIcon}
                    </span>
                    <input
                        // id={InputID} className={`form-control ${GetInputValidLabel()} ${GetInputValidMonth()}`}
                        id={InputID} className={`form-control`}
                        tabIndex={props?.tabIndex}
                        value={InputValue}
                        onChange={(e) => OnSearch(e.target.value)}
                        onFocus={OnFocus}
                        onBlur={(e) => { OffClose(); }}
                        autoComplete="off"
                        autoFocus={InputAutoFocus}
                        onKeyDown={(event) => { OnKeyDown(event); nextElementFocus({ tabIndex: props.tabIndex, reference: { airlineRef: props.reference } }) }}
                        ref={props?.reference}
                    />
                </div>
            </Popover>
        </React.Fragment>
    );
};
interface DateInputProps extends BaseDateInputProps {
    Value: Date | undefined;
    OnDateChange(value: Date | undefined): void
    segment: Date | undefined;
}
interface BaseDateInputProps {
    SelectNextInput?(): void
    InputID?: string
    PopoverPosition?: PopoverPosition[];
    MinDate?: Date;
    MaxDate?: Date;
    InputGroup: boolean;
    InputClassNames?: string;
    PlaceHolder?: string;
    IsDob: boolean;
    booked1?: boolean;
    booked2?: boolean;
    bookedDays1?: any;
    bookedDays2?: any;
    bookedDays3?: any;
    bookedStyle1?: any;
    bookedStyle2?: any;
    bookedStyle3?: any;
    btnRef?: any;
    reference?: any;
    tabIndex?: any;
    required?: string;
    segment: Date | undefined;
}
const DateInput: React.FC<DateInputProps> = props => {
    const [inputStringValue, setInputStringValue] = useState("");
    const debug = false;
    const [inputDateValue, setInputDateValue] = useState<Date | undefined>(undefined);
    const [guessedDate, setGuessedDate] = useState<GuessDateResult | undefined>(undefined);
    const [inputActive, setInputActive] = useState(false);
    const dateDisplay = useFormatDate(props.Value);
    const dateFormat = useDateFormat();

    useEffect(() => {
        if (props.Value) {
            // console.log("dateDisplay", dateDisplay, props.Value);
            setInputStringValue(dateDisplay);
            setInputDateValue(props.Value);
            // props.btnRef?.current.focus();
        }
        else {
            setInputStringValue("");
            setInputDateValue(props.Value);
        }
    }, [props.Value])

    useEffect(() => {
        setInputStringValue("");
        setInputDateValue(undefined);
    }, [])

    function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        // console.log("onInputChange fired");
        const value: string = event.target.value;
        setGuessedDate(GuessDate(value, dateFormat, props.MinDate, props.MaxDate, props.IsDob));
        setInputStringValue(value);
    }

    function onInputFocus(event: React.FocusEvent<HTMLInputElement>): void {
        event.target.select()
    }

    function onInputSelect(event: React.SyntheticEvent<HTMLInputElement, Event>) {
        setInputActive(true)
    }

    function OnDayPickerSelection(day: Date | undefined, selectedDay: Date, activeModifiers: ActiveModifiers, e: React.MouseEvent) {
        if (debug) {
            console.log("OnDayPickerSelection fired");
        }
        OnSelectDate(selectedDay);
    }

    function OnSelectDate(date: Date | undefined) {
        if (debug) {
            console.log("OnSelectDate fired");
        }
        setInputActive(false);
        props.OnDateChange(date);
        if (props.SelectNextInput) {
            // console.log("Datepicker selectnext fired");
            props.SelectNextInput();
        }
    }

    function handleYearMonthChange(date: Date) {
        if (debug) {
            console.log("handleYearMonthChange fired");
        }
        setInputDateValue(date);
    }

    function OnPopoverClickOutside(event: MouseEvent) {
        if (debug) {
            console.log("OnPopoverClickOutside fired");
        }
        setInputActive(false);
        OnInputLeave();
    }

    function OnTakeGuessedDateButtonclick() {
        if (guessedDate) {
            OnSelectDate(guessedDate.Result);
        }
    }

    function OnClear() {
        props.OnDateChange(undefined);
    }

    function OnClosePicker() {
        setTimeout(() => {
            if (debug) {
                console.log("OnClosePicker");
            }
            if (guessedDate) {
                OnSelectDate(guessedDate.Result);
            }
            setInputActive(false);
        }, 500);
    }


    function onInputBlur(event: any) {
        // Check if the related target of the blur event is null or not the calendar
        if (event.relatedTarget === null || event.relatedTarget?.classList.contains('css-sun3y7') || event.relatedTarget?.classList.contains('css-195lt0x')) {
            // Do not set inputActive to false if the focus shifted to the calendar
            return;
        }
        OnInputLeave();
        setInputActive(false);

    }

    const before: Matcher | undefined = props.MinDate ? { before: props.MinDate } : undefined;
    const after: Matcher | undefined = props.MaxDate ? { after: props.MaxDate } : undefined;
    const match: Matcher[] = [before ? before : [], after ? after : []];

    if (props.segment && inputDateValue == undefined) {
        setInputDateValue(new Date(props.segment))
    }

    function ToolTipContent(popoverState: PopoverState): JSX.Element {
        // const res = this.GetDateGuess();
        return <ArrowContainer position={popoverState.position} childRect={popoverState.childRect} popoverRect={popoverState.popoverRect} arrowColor="black" arrowSize={2}>
            <div>
                <Card>
                    <button type="button" className="btn btn-outline btn-sm me-2" onClick={OnClosePicker}><FontAwesomeIcon icon={faTimes} /> close</button>
                    <Calendar
                        className="smartCalenderDiv"
                        mode="single"
                        onSelect={OnDayPickerSelection}
                        selected={inputDateValue}
                        modifiers={{ booked1: props.bookedDays1, booked2: props.bookedDays2, booked3: props.bookedDays3 }}
                        modifiersStyles={{ booked1: props.bookedStyle1, booked2: props.bookedStyle2, booked3: props.bookedStyle3 }}
                        // numberOfMonths={1}
                        disabled={match}
                        showOutsideDays={false}
                        fromMonth={props.MinDate}
                        toMonth={props.MaxDate}
                        fromYear={props.MinDate?.getFullYear()}
                        toYear={props.MaxDate?.getFullYear()}
                        defaultMonth={inputDateValue} />
                    <div className="btn-group">
                        <button type="button" className="btn btn-sm btn-danger" style={{ color: "white" }} onClick={OnClear}><FontAwesomeIcon icon={faTrash} /></button>
                        {guessedDate &&
                            <button type="button" className="btn ms-2 btn-sm btn-primary" onClick={OnTakeGuessedDateButtonclick}>
                                <FontAwesomeIcon icon={faAngleDoubleRight} /> {guessedDate ? guessedDate.ResultString : "type to see autocomplete.."}
                            </button>
                        }
                    </div>

                </Card>
            </div>
        </ArrowContainer>
    }

    function OnInputLeave() {
        if (debug) {
            console.log("Onleave fired")
        }

        if (inputStringValue !== "") {
            if (guessedDate) {
                OnSelectDate(guessedDate.Result);
            }
        }
        else {
            OnSelectDate(undefined);
        }
    }

    function OnInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if ((e.which === 13 || e.which === 9 || e.which === 27)) { // TAB / ENTER / ESC
            setInputActive(false);
            // props.btnRef?.current.focus();
            if (!(e.which === 13 && e.ctrlKey)) {
                if (e.which !== 9) {// TAB
                    e.preventDefault();
                    e.stopPropagation();
                }
                OnInputLeave();
            }

        }

    }

    return <Popover
        positions={props.PopoverPosition !== undefined ? props.PopoverPosition : ['bottom', 'left', 'right', 'top']}
        isOpen={(inputActive)}
        containerStyle={{ zIndex: "999999" }}
        onClickOutside={OnPopoverClickOutside}
        content={(popoverState: PopoverState) => (ToolTipContent(popoverState))}>
        <div className={`${props.InputGroup ? "input-group" : ""}`}>
            {props.InputGroup &&
                <span className="input-group-text" title="Flight Date">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                </span>
            }
            <input
                value={inputStringValue}
                tabIndex={props.tabIndex}
                onChange={onInputChange}
                onBlur={onInputBlur}
                type="text"
                className={`${props.InputClassNames ? props.InputClassNames : "form-control"}`}
                autoComplete={"off"}
                onKeyDown={(e) => { OnInputKeyDown(e); nextElementFocus({ tabIndex: props.tabIndex, reference: { InputDate: props.reference } }) }}
                onFocus={onInputFocus}
                onSelect={onInputSelect}
                id={props.InputID}
                placeholder={props.PlaceHolder !== undefined ? props.PlaceHolder : "Date"}
                ref={props.reference}
                required
            />
        </div>
    </Popover>
}

interface ConfirmationModalProps {
    show: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    message: string;
    loading: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ show, onConfirm, onCancel, message, loading }) => {
    return (
        <>
            <div className="modal-overlay zIndexBg">
                {show ?
                    <div className="modal-content">
                        <p>{message}</p>
                        <div className="d-flex justify-content-center gap-2">
                            <button className="btn btn-primary" onClick={onConfirm}>Yes</button>
                            <button className="btn btn-danger" onClick={onCancel}>No</button>
                        </div>
                    </div> : null
                }

                {loading ?
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
                        <Spinner className="">
                            Loading...
                        </Spinner>
                    </div> : null}
            </div>
            <div
                className={`${!show ? "" : "modalEffectBg"}`}
            ></div>
        </>
    );
};
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { DateDisplay, DateTimeDisplay } from "components/Shared/DateDisplay";
import { SharedPriceDisplay } from "components/Shared/PriceDisplay";
import { TabbedContent, TabbedContentProps } from "components/Shared/TabbedContent";
import React, { useState } from "react";
import { TicketedServiceDataModel } from "WebApiClient";

export const PnrTicketedServiceCard: React.FC<{ data: TicketedServiceDataModel[] }> = ({ data }) => {

    const [checkCurrentTab, setCheckCurrentTab] = useState(0);

    const TabContent: TabbedContentProps = {
        EnableJump: true,
        Tabs: data.map((e, i) => {
            return {
                Component: <ServiceItem key={"servIt" + i} data={e} />
                ,
                Title: `#${i + 1}`,
                Icon: faInfoCircle,
                AmountIndicator: undefined
            }
        })

    }

    return (
        <div className="card card-primary">
            <div className="card-header card-header-primary">Ticketed services <span className="badge badge-primary badge-pill align-top">{data.length}</span></div>
            <div className="card-body">
                <TabbedContent {...TabContent} setCheckCurrentTab={setCheckCurrentTab} checkCurrentTab={checkCurrentTab}/>

                {/* <div className="row">
                    {data.map((storedFare, index) =>
                        <div className="col-12" key={index}>
                            <ServiceItem data={storedFare} storedFareInde={index} />
                        </div>
                    )}
                </div> */}
            </div>
        </div>
    );


}

const ServiceItem: React.FC<{ data: TicketedServiceDataModel }> = ({ data }) => {
    const { associatedTicketIDs, coupons, dateOfIssue,
        documentNumber, equivalentServicePrice, fareCalculation,
        formOfPayment, iataNumber, passengerID,
        platingCarrier, pointOfIssue,
        serviceText, status } = data;

    return (
        <div className="card mb-2">
            {/* <div className="card-header card-header-primary">Ticketed service #{storedFareInde + 1}</div> */}
            <div className="card-body">
                <div className="row">
                    <div className="col-12 mb-2">
                        <div className="card">
                            <div className="card-header card-header-primary">General</div>
                            <div className="card-body">
                                <table className="table table-sm table-striped table-hover table-bordered">
                                    <tbody>
                                        <tr>
                                            <td className="font-weight-bold">Status</td>
                                            <td>{status}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">Text</td>
                                            <td>{serviceText}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">IATA</td>
                                            <td>{iataNumber}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">Date of issue</td>
                                            <td>{dateOfIssue ? <DateTimeDisplay date={dateOfIssue} /> : ""}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">Document number</td>
                                            <td>{documentNumber}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">Equiv. Price</td>
                                            <td><SharedPriceDisplay price={equivalentServicePrice} /></td>
                                        </tr>

                                        <tr>
                                            <td className="font-weight-bold">Calculation</td>
                                            <td>{fareCalculation}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">Form of payment</td>
                                            <td>{formOfPayment}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">Passenger number</td>
                                            <td>{passengerID}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">Plating carrier</td>
                                            <td>{platingCarrier}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">Point of issue</td>
                                            <td>{pointOfIssue}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">Tickets</td>
                                            <td>{associatedTicketIDs.join(",")}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>


                    {coupons.length > 0 &&
                        <div className="col-12 mb-2">
                            <div className="card">
                                <div className="card-header card-header-primary">Coupons</div>
                                <div className="card-body">
                                    <table className="table table-sm table-striped table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th className="font-weight-bold">Arrival</th>
                                                <th className="font-weight-bold">Carrier</th>
                                                <th className="font-weight-bold">Status</th>
                                                <th className="font-weight-bold">Departure</th>
                                                <th className="font-weight-bold">Document number</th>
                                                <th className="font-weight-bold">Document type</th>
                                                <th className="font-weight-bold">Extensions</th>
                                                <th className="font-weight-bold">NVB</th>
                                                <th className="font-weight-bold">NVA</th>
                                                <th className="font-weight-bold">Refundable</th>
                                                <th className="font-weight-bold">Service code</th>
                                                <th className="font-weight-bold">Service text</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {coupons.map((coupon, index) =>
                                                <tr key={"couponId"+index}>
                                                    <td>{coupon.arrival}</td>
                                                    <td>{coupon.carrier}</td>
                                                    <td>{coupon.couponStatus}</td>
                                                    <td>{coupon.departure}</td>
                                                    <td>{coupon.documentNumber}</td>
                                                    <td>{coupon.documentType}</td>
                                                    <td>{coupon.extensions}</td>
                                                    <td><DateDisplay date={coupon.notValidAfter} /></td>
                                                    <td><DateDisplay date={coupon.notValidBefore} /></td>
                                                    <td>{coupon.refundable !== undefined ? coupon.refundable ? "Yes" : "No" : ""}</td>
                                                    <td>{coupon.serviceCode}</td>
                                                    <td>{coupon.serviceText}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </div>

    );


}


